import type { GetStaticProps } from 'next';
import type { Hero } from 'types';
import type { FrontPage } from 'lib/queries/pages';
import React from 'react';
import { Paragraph, Wrap, useHeadingStyles } from '@leafwell/components';
import Image from 'next/image';
import Blocks from 'components/blocks';
import Figure from 'components/figure';
import PageWrap from 'components/page-wrap';
import StateSelect from 'components/state-select';
import PresenceJson from 'components/structured-data/presence';
import { getFrontPage } from 'lib/queries/pages';

const HomeHero: React.FC<Hero> = ({ image, title, steps }) => {
  const headingStyles = useHeadingStyles('1').replace(
    /(\s|^)text-([a-zA-Z0-9]*)/,
    ' text-3xl',
  );

  return (
    <section className="relative bg-gradient-to-r from-gray-200 via-gray-200 to-lilac-100 xl:-mt-40 xl:pt-40 before:absolute before:md:w-3/4 before:h-full before:md:right-0 before:md:bottom-0 before:bg-gradient-to-b before:from-lilac-100 before:to-green-200 before:gradient-mask-l-0">
      <Wrap
        spacer={false}
        className="lg:grid lg:grid-cols-3 lg:gap-x-3 pt-12 xl:pt-0 pb-12 lg:pb-0 relative lg:min-h-[540px] xl:min-h-[580px]"
      >
        <div className="lg:self-center grid gap-y-6 xl:gap-y-12 lg:pb-12 col-span-2">
          <div>
            <h1
              className={headingStyles}
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </div>
          <StateSelect showLoginCta={true} />
          {steps && (
            <ul className="grid sm:grid-cols-2 gap-x-6 gap-y-6 xl:pt-3">
              {steps.map(({ title, description }, key) => (
                <li
                  key={key}
                  className="grid grid-cols-2-auto gap-x-3 gap-y-1 items-start"
                >
                  <Image
                    height={20}
                    width={20}
                    priority={true}
                    src="/check-gradient.svg"
                    alt=""
                    className="row-span-2 mt-list-offset"
                  />
                  <Paragraph
                    size="large"
                    className="font-medium"
                    dangerouslySetInnerHTML={{ __html: title }}
                  />
                  <Paragraph
                    size="small"
                    variant="medium"
                    dangerouslySetInnerHTML={{ __html: description }}
                    tagName="article"
                  />
                </li>
              ))}
            </ul>
          )}
        </div>
        {image && (
          <Figure
            alt={image.alt}
            src={image.src}
            width={image.width}
            height={image.height}
            priority={true}
            className="hidden lg:block lg:self-end"
            sizes="(max-width: 1023px) 0vw, (max-width: 1200px) 33vw, 33vw"
          />
        )}
      </Wrap>
    </section>
  );
};
const Home: React.FC<FrontPage> = ({
  page: {
    title,
    seo,
    acf: { pageType, blocks, hero },
  },
  partnersReviews,
}) => {
  return (
    <PageWrap seo={seo} title={title} pageType={pageType}>
      <PresenceJson />
      <HomeHero {...hero} />
      <Blocks
        blocks={blocks}
        pageType={pageType}
        partnersReviews={partnersReviews}
      />
    </PageWrap>
  );
};

export const getStaticProps: GetStaticProps = async context => {
  return await getFrontPage(context);
};

export default Home;
