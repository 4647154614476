import type { ACF, Page, Translation } from 'types';
import React, { useMemo } from 'react';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { ToastProvider } from '@leafwell/components';
import { useShareImages } from 'hooks/useShareImages';
import { useWordPressContext } from 'contexts/wordpress-provider';

const Footer = dynamic(() => import('./footer'));
const Header = dynamic(() => import('./header'));

type PageWrapProps = Pick<Page, 'title' | 'seo'> & {
  pageType?: ACF['pageType'];
  children: React.ReactElement | React.ReactElement[];
};

const PageWrap: React.FC<PageWrapProps> = ({
  children,
  seo,
  pageType,
  title,
}) => {
  const { seoSettings, pageTranslations, siteTitle } = useWordPressContext();
  const { locale } = useRouter();
  const image = useShareImages({
    type: 'full',
    title,
  });

  const hrefLangAnnotations = useMemo(() => {
    if (pageTranslations?.length > 0 && typeof window !== 'undefined') {
      const annotations: Omit<Translation, 'locale'>[] = pageTranslations;
      //hreflang must point to itself
      annotations.push({
        uri: window?.location.pathname,
        slug: locale,
      });
      //hreflang must have x-default
      annotations.push({
        uri:
          locale === 'en'
            ? window?.location?.pathname
            : pageTranslations?.find(({ slug }) => slug === 'en')?.uri,
        slug: 'x-default',
      });
      return annotations;
    }
    return null;
  }, [pageTranslations, locale]);

  return (
    <>
      <NextSeo
        key={`page-seo-${seo?.canonical}`}
        title={seo?.title ?? siteTitle}
        description={seo?.description}
        openGraph={{
          title: seo?.opengraph?.title,
          description: seo?.opengraph?.description,
          images: [image],
          url: seo?.canonical,
          site_name: siteTitle,
          locale,
        }}
        nofollow={seo?.metaRobotsNofollow || false}
        noindex={seo?.metaRobotsNoindex || false}
        robotsProps={{ noarchive: seo?.metaRobotsNoarchive || false }}
        canonical={seo?.canonical}
        twitter={{
          cardType: seoSettings?.social?.twitter?.cardType,
          handle: seoSettings?.social?.twitter?.authorFallback,
          site: seoSettings?.social?.twitter?.profile,
        }}
        facebook={{ appId: seoSettings?.social?.facebook?.appId }}
        languageAlternates={
          hrefLangAnnotations
            ? hrefLangAnnotations.map(({ slug, uri }) => ({
                hrefLang: slug,
                href: uri ? `${window?.location?.origin}${uri}` : null,
              }))
            : null
        }
      />
      <ToastProvider>
        {pageType !== 'google_my_business' &&
          pageType !== 'vpd_landing' &&
          pageType !== 'webinar_landing' &&
          pageType !== 'affiliate_landing' && <Header pageType={pageType} />}
        <main className="grow xl:pt-40">{children}</main>
        {pageType !== 'google_my_business' && (
          <Footer
            showNavFooter={
              pageType !== 'affiliate_landing' &&
              pageType !== 'vpd_landing' &&
              pageType !== 'webinar_landing'
            }
          />
        )}
      </ToastProvider>
    </>
  );
};

export default PageWrap;
