import type { Page } from 'types/page';
import type { PartnersReviewsResult } from 'lib/queries/reviews';
import type { WysiwygContentProps } from './wysiwyg-content';

import React from 'react';
import dynamic from 'next/dynamic';

import { Wrap } from '@leafwell/components';
import { Blocks as BlocksType } from 'types/blocks';

const Accordion = dynamic(() => import('./accordion'));
const CardsBlock = dynamic(() => import('./cards-block'));
const CardsGrid = dynamic(() => import('./cards-grid'));
const RichContentHighlight = dynamic(() => import('./wysiwyg-content'));
const MedicalCardHighlight = dynamic(() => import('./medical-card-highlight'), {
  ssr: false,
});
const ReviewsBlock = dynamic(() => import('./reviews-block'));
const SeenOn = dynamic(() => import('./seen-on'));
const StatsHighlight = dynamic(() => import('./stats-highlight'));
const Steps = dynamic(() => import('./steps'));
const Team = dynamic(() => import('./team'));
const Info = dynamic(() => import('./info'));
const Method = dynamic(() => import('./method'));
const RoundedSection = dynamic(() => import('./rounded-section'));
const SignupFormBlock = dynamic(() => import('./signup-form-block'), {
  ssr: false,
});
const Faqs = dynamic(() => import('./faqs'));
const TwoColumnSection = dynamic(() => import('./two-column-section'));
const CardsList = dynamic(() => import('./cards-list'));
const CircleList = dynamic(() => import('./circle-list'));
const ColumnList = dynamic(() => import('./column-list'));
const Stats = dynamic(() => import('./stats'));
const Carousel = dynamic(() => import('./carousel'));
const Intro = dynamic(() => import('./intro'));
const Line = dynamic(() => import('./line'));
const StateLawsMapCard = dynamic(() => import('./state-laws-card'));
const ConditionsRelated = dynamic(() => import('./conditions-related'));
const Timeline = dynamic(() => import('./timeline'));
const Marquee = dynamic(() => import('./marquee'));
const VideoYoutube = dynamic(() => import('./video-youtube'));
const Image = dynamic(() => import('./image'));
const InfoCard = dynamic(() => import('./info-card'));
const CannabisCounselingCta = dynamic(
  () => import('./cannabis-counseling-cta'),
);
const AppCta = dynamic(() => import('./app-cta'));
const SizzleVideo = dynamic(() => import('./sizzle-video'));
const MosaicCards = dynamic(() => import('./mosaic-cards'));
const ColumnChart = dynamic(() => import('./column-chart'));
const InfoGrid = dynamic(() => import('./info-grid'));
const TableBlock = dynamic(() => import('./table-block'));
const BrazeContentCard = dynamic(() => import('./braze-card'));
const BrazeInAppMessage = dynamic(() => import('./braze-message'));
const InfoIcons = dynamic(() => import('./info-icons'));
const SimpleCardCta = dynamic(() => import('./simple-card-cta'));
const TableOfContentsBlock = dynamic(() => import('./table-contents-block'));
const CardsImage = dynamic(() => import('./cards-image'));
const ContentAndMedia = dynamic(() => import('./content-and-media'));
const PriceComparison = dynamic(() => import('./price-comparison'));
const ContentAndBanner = dynamic(() => import('./content-and-banner'));
const PartnersLocation = dynamic(() => import('./partners-location'));
const FormBlock = dynamic(() => import('./form-block'), {
  ssr: false,
});
const Events = dynamic(() => import('./events-block'));
const OnceApproved = dynamic(() => import('./once-approved'));
const DateTime = dynamic(() => import('./date-time-block'));
const CardSteps = dynamic(() => import('./card-steps-block'));

type BlockProps = {
  blocks: BlocksType[];
  pageType?: string;
  modified?: string;
  partnersReviews?: PartnersReviewsResult['partnersReviews'];
  stateProductImage?: Page['acf']['stateProductImage'];
  states?: Page['acf']['states'];
};

const Blocks: React.FC<BlockProps> = ({
  blocks,
  pageType,
  states,
  modified,
  stateProductImage,
  partnersReviews,
}) => {
  return blocks ? (
    <>
      {blocks.map((block, key) => {
        switch (block.acfFcLayout) {
          case 'accordion':
            return <Accordion key={key} {...block} />;
          case 'app_cta':
            return <AppCta key={key} {...block} />;
          case 'cards':
            return <CardsBlock key={key} {...block} />;
          case 'medical_card_cta':
            return <MedicalCardHighlight key={key} {...block} />;
          case 'reviews':
            return (
              <ReviewsBlock
                key={key}
                pageType={pageType}
                reviews={partnersReviews}
                {...block}
              />
            );
          case 'seen_on':
            return <SeenOn key={key} {...block} />;
          case 'info':
            return <Info key={key} {...block} />;
          case 'line':
            return <Line key={key} {...block} />;
          case 'method':
            return <Method key={key} {...block} />;
          case 'rounded_section':
            return <RoundedSection key={key} {...block} />;
          case 'cards_list':
            return <CardsList key={key} {...block} />;
          case 'circle_list':
            return <CircleList key={key} {...block} />;
          case 'intro':
            return <Intro key={key} {...block} />;
          case 'stats_highlight':
            return <StatsHighlight key={key} {...block} />;
          case 'carousel':
            return <Carousel key={key} {...block} />;
          case 'sign_up_form':
            return (
              <SignupFormBlock
                key={key}
                title={block.title}
                subTitle={block.subTitle}
                formDescription={block.formDescription}
                address={block.address}
                sectionId={block.sectionId}
                layout={block.layout}
                formType={block.formType}
                formUrl={block.formUrl}
                pageType={pageType}
                activeStates={states}
                redirectMedicalCard={false}
                redirectCannabisCounseling={pageType === 'cannabis_counseling'}
              />
            );
          case 'faqs':
            return <Faqs key={key} {...block} />;
          case 'rich_text':
            return (
              <RichContentHighlight
                key={key}
                {...(block as WysiwygContentProps)}
              />
            );
          case 'state_laws_map':
            return <StateLawsMapCard key={key} {...block} />;
          case 'medical_conditions':
            return <ConditionsRelated key={key} {...block} />;
          case 'blog_posts':
            return (
              <Wrap id={block.sectionId} size="large" key={key}>
                <CardsGrid
                  title={block.title}
                  items={block.posts}
                  displayBlogButton={true}
                  variant="smallFeatured"
                />
              </Wrap>
            );
          case 'timeline':
            return <Timeline key={key} {...block} />;
          case 'marquee':
            return <Marquee key={key} {...block} />;
          case 'youtube_video':
            return <VideoYoutube key={key} {...block} />;
          case 'image':
            return <Image key={key} {...block} />;
          case 'info_card':
            return <InfoCard key={key} {...block} />;
          case 'sizzle_video':
            return <SizzleVideo key={key} {...block} />;
          case 'mosaic_cards':
            return <MosaicCards key={key} {...block} />;
          case 'column_chart':
            return <ColumnChart key={key} {...block} />;
          case 'info_grid':
            return <InfoGrid key={key} {...block} />;
          case 'table':
            return (
              <TableBlock key={key} image={stateProductImage} {...block} />
            );
          case 'info_icons':
            return <InfoIcons key={key} {...block} />;
          case 'simple_card_cta':
            return <SimpleCardCta key={key} {...block} />;
          case 'table_of_contents':
            return <TableOfContentsBlock key={key} {...block} />;
          case 'cards_image':
            return <CardsImage key={key} {...block} />;
          case 'content_and_media':
            return <ContentAndMedia key={key} {...block} />;
          case 'price_comparison':
            return <PriceComparison key={key} modified={modified} {...block} />;
          case 'content_and_banner':
            return <ContentAndBanner key={key} {...block} />;
          case 'partners_location':
            return <PartnersLocation key={key} {...block} />;
          case 'form':
            return <FormBlock key={key} pageType={pageType} {...block} />;
          case '2_column_section':
            return <TwoColumnSection key={key} {...block} />;
          case 'steps':
            return <Steps key={key} {...block} />;
          case 'events':
            return <Events key={key} {...block} />;
          case 'cannabis_counseling_cta':
            return <CannabisCounselingCta key={key} {...block} />;
          case 'once_approved':
            return <OnceApproved key={key} {...block} />;
          case 'date_time':
            return <DateTime key={key} {...block} />;
          case 'braze_card':
            return <BrazeContentCard key={key} {...block} />;
          case 'braze_message':
            return <BrazeInAppMessage key={key} {...block} />;
          case 'team':
            return <Team key={key} {...block} />;
          case 'stats':
            return <Stats key={key} {...block} />;
          case 'column_list':
            return <ColumnList key={key} {...block} />;
          case 'card_steps':
            return <CardSteps key={key} {...block} />;
          default:
            return null;
        }
      })}
    </>
  ) : null;
};

export default Blocks;
