import React, { useCallback, useMemo } from 'react';
import {
  Button,
  Form,
  Paragraph,
  SelectFieldNativeControlled as Select,
} from '@leafwell/components';

import { useWordPressContext } from 'contexts/wordpress-provider';
import { setStateCookie } from 'lib/cookies/state-cookie';
import { useStatesList } from 'hooks/useStatesList';
import { useCurrentState } from 'hooks/useCurrentState';
import { useEventsTracker } from 'contexts/eventsTracker';

const StateSelect: React.FC<{
  activeState?: string;
  buttonLabel?: string;
  className?: string;
  label?: string;
  variant?: 'default' | 'small';
  inlineButton?: boolean;
  showLoginCta?: boolean;
}> = ({
  activeState,
  buttonLabel,
  className,
  label,
  variant = 'default',
  inlineButton = true,
  showLoginCta = false,
}) => {
  const showButton = useMemo(
    () => !(variant === 'small' && label?.length > 0),
    [variant, label],
  );
  const statesList = useStatesList();
  const defaultState = useCurrentState();
  const { cardPage, statePages, translations } = useWordPressContext();
  const selectedState = useMemo(
    () => activeState || defaultState || '',
    [activeState, defaultState],
  );
  const submitForm = useCallback(
    (stateCode?: string) => {
      const hasStateCode = stateCode && stateCode !== '';
      const url = hasStateCode
        ? statePages.find(({ code }) => code === stateCode)?.uri
        : cardPage?.uri;

      if (hasStateCode) {
        setStateCookie(stateCode);
      }

      if (url) {
        window.location.assign(url);
      }
    },
    [cardPage, statePages],
  );
  const { trackEvent } = useEventsTracker();

  return statePages ? (
    <div className="grid gap-y-2">
      <Form
        className={[
          'sm:self-start gap-x-3',
          showButton
            ? inlineButton
              ? 'sm:grid-cols-inline-form sm:justify-between sm:max-w-md gap-y-2'
              : 'gap-y-2'
            : 'sm:grid-cols-2-auto max-w-md gap-y-1',
          className,
        ].join(' ')}
        defaultValues={{ stateCode: selectedState }}
        onSubmit={({ stateCode }) => {
          submitForm(stateCode);
          trackEvent({
            event: 'select_content',
            content_type: translations[stateCode],
            item_id: 'apply_medical_card',
            origin: 'block_card',
          });
        }}
      >
        {label && (
          <Paragraph className="self-center xl:text-3xl">{label}</Paragraph>
        )}
        <Select
          {...{
            icon: '/us_flag.svg',
            label: translations['State'],
            labelVisible: false,
            name: 'stateCode',
            placeholder: translations['Select State'],
            options: statesList,
            ...(showButton
              ? {}
              : { onChange: ({ target }) => submitForm(target.value) }),
          }}
        />
        {showButton && (
          <Button
            {...{
              label: buttonLabel || translations['Get started'],
              rounded: true,
              type: 'submit',
              variant: 'secondary',
            }}
          />
        )}
      </Form>
      {showLoginCta ? (
        <p className="text-sm">
          Already a Leafwell patient?
          <br />
          <a
            className="font-medium underline underline-offset-3"
            target="_blank"
            href={process.env.NEXT_PUBLIC_LOGIN_URL}
          >
            Log in here
          </a>
        </p>
      ) : null}
    </div>
  ) : null;
};

export default StateSelect;
